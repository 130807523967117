.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.editor {
  margin-top: 140px;
  margin-bottom: 140px;
  width: 600px;
  min-height: 100px;

  position: relative;
}

.editor .textarea {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: -1px;
}

.editor textarea {
  margin: 0;
  border-radius: 0;
  border: none;
  resize: none;

  width: 100%;
  height: 100%;

  color: white;
  background-color: transparent;

  font-size: calc(10px + 2vmin);
}

.editor textarea:focus {
  outline: none;
}

.editor.backdrop {
  overflow: auto;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: #fff;
}

.editor.highlights {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.editor .user-input {
  color: transparent;
}

.editor .suggestion {
  color: #bbb;
}

.editor .ghost-input {
  color: #888;
}
